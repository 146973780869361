import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PDF from "../downloads/deklaracja-przystapienie.pdf"

const DeklaracjaPrzystapienie = () => {
  return (
    <Layout>
      <Seo title="Deklaracja ZPP" />
      <div className="px-3">
        <div className="w-full flex flex-col items-center my-5">
          <StaticImage
            alt="logo"
            src="../images/hero-logo.png"
            className="w-3/12 h-auto mx-auto"
          />
          <p className="text-center w-1/2 mx-auto">
            Poniżej wyświetlony jest dokument. Możesz przeczytać go w
            przeglądarce lub pobrać na swoje urządzenie przy pomocy przycisku
            pobierz znajdującego się pod ramką dokumentu.
          </p>
        </div>

        <iframe
          src={PDF}
          controls
          title="W. Kornowski"
          className="w-full md:w-2/3 h-screen mx-auto bg-bg border"
          frameborder="0"
        />

        <a
          href={PDF}
          target="_blank"
          download
          rel="noreferrer noopener"
          className="bg-primary px-3 py-2 text-bg relative rounded-lg block w-max my-5 font-medium mx-auto hover:scale-105 transition-all"
        >
          Pobierz plik (PDF){" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </a>
      </div>
    </Layout>
  )
}

export default DeklaracjaPrzystapienie
